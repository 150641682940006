import moment from "moment";
export const dataByTeam = (data, name) =>
  data
    ?.filter((i) => i?.sdm?.includes(name) && i?.stage !== "Cancelled")
    .map((t) => {
      return {
        start: new Date(
          moment(t.startDate, "DD/MM/YYYY").format("YYYY, MM, DD")
        ),
        end: new Date(
          moment(t.finishDate, "DD/MM/YYYY").format("YYYY, MM, DD")
        ),
        name: `[${t["projectId"].slice(-3)}] ${t.projectName}`,
        id: name + "-" + t.projectId,
        type: "task",
        progress: null,
        project: name.toLowerCase(),
      };
    });
