import React, { useState, useEffect } from "react";
import { Gantt } from "gantt-task-react";
import moment from "moment/moment";
import "gantt-task-react/dist/index.css";
import { navigate } from "gatsby";

function GanttChart(props) {
  const { data, type, byTeam } = props;
  const [tasks, setTasks] = useState(
    data?.map((i) => {
      return {
        start:
          new Date(moment(i.startDate, "DD/MM/YYYY").format("YYYY, MM, DD")) ||
          new Date(),
        end: new Date(
          moment(i.finishDate, "DD/MM/YYYY").format("YYYY, MM, DD")
        ),
        name: `[${i["projectId"].slice(-3)}] ${i.projectName}`,
        id: i.projectId,
        type: "task",
        progress: null,
        isDisabled: false,
      };
    })
  );
  useEffect(() => {
    if (type === "team") {
      setTasks(byTeam);
    } else {
      setTasks(
        data?.map((i) => {
          return {
            start:
              new Date(
                moment(i.startDate, "DD/MM/YYYY").format("YYYY, MM, DD")
              ) || new Date(),
            end: new Date(
              moment(i.finishDate, "DD/MM/YYYY").format("YYYY, MM, DD")
            ),
            name: `[${i["projectId"].slice(-3)}] ${i.projectName}`,
            id: i.projectId,
            type: "task",
            progress: null,
            isDisabled: false,
          };
        })
      );
    }
  }, [type]);

  const onClickProject = ({ id }) => {
    const realId = type === "project" ? id : id.substring(id.indexOf("-") + 1);
    if (id.includes("ENG-")) {
      navigate(`/project-details?id=${realId}`);
    } else {
      return false;
    }
  };

  const TaskListComlum = ({ tasks }) => {
    return (
      <div>
        {tasks.map((item) => {
          return (
            <div className="gantt-project">
              <div>{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const TaskListHeader = () => {
    return (
      <div className="gantt-header">
        <div style={{ paddingLeft: "1rem" }} />
        <div>Project</div>
      </div>
    );
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  return (
    <div className="roadmap">
      {tasks?.length > 0 && type === "project" ? (
        <Gantt
          tasks={tasks}
          viewMode="Month"
          viewDate={new Date()}
          todayColor="rgba(0, 188, 180, 0.4)"
          TaskListTable={TaskListComlum}
          TaskListHeader={TaskListHeader}
          onClick={onClickProject}
        />
      ) : (
        <Gantt
          tasks={tasks}
          viewMode="Month"
          viewDate={new Date()}
          todayColor="rgba(0, 188, 180, 0.4)"
          onClick={onClickProject}
          onExpanderClick={handleExpanderClick}
        />
      )}
    </div>
  );
}

export default GanttChart;
