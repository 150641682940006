export const AtiratTop = [
  {
    start: new Date("2024, 1, 1"),
    end: new Date("2024, 12, 31"),
    name: "Atirat",
    id: "atirat",
    progress: null,
    type: "project",
    hideChildren: true,
  },
];

export const ArtTop = [
  {
    start: new Date("2024, 1, 1"),
    end: new Date("2024, 12, 31"),
    name: "Art",
    id: "art",
    progress: null,
    type: "project",
    hideChildren: true,
  },
];

export const ParamaTop = [
  {
    start: new Date("2024, 1, 1"),
    end: new Date("2024, 12, 31"),
    name: "Parama",
    id: "parama",
    progress: null,
    type: "project",
    hideChildren: true,
  },
];

export const PeerasakTop = [
  {
    start: new Date("2024, 1, 1"),
    end: new Date("2024, 12, 31"),
    name: "Peerasak",
    id: "peerasak",
    progress: null,
    type: "project",
    hideChildren: true,
  },
];

export const SatthaTop = [
  {
    start: new Date("2024, 1, 1"),
    end: new Date("2024, 12, 31"),
    name: "Sattha",
    id: "sattha",
    progress: null,
    type: "project",
    hideChildren: true,
  },
];

export const MongkolTop = [
  {
    start: new Date("2024, 1, 1"),
    end: new Date("2024, 12, 31"),
    name: "Mongkol",
    id: "mongkol",
    progress: null,
    type: "project",
    hideChildren: true,
  },
];
