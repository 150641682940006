import React from "react";
import Seo from "../../components/seo";
import LocalDevProject from "../../components/ProjectList/localDev";

const LocalDevProjectPage = (pros) => (
  <>
    <Seo title="Local Dev Projects" />
    <LocalDevProject />
  </>
);
export default LocalDevProjectPage;
