import React, { useEffect, useState } from "react";
import { useAdminState } from "../../context/AdminContext";
import getProductionList from "../../services/getProductionList";
import moment from "moment";
import { Container, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Loading from "../../shared/Loading";
import Breadcrumb from "../../shared/Breadcrumbs";
import GanttChart from "../../shared/GanttChart";
import { FolderOpenIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { dataByTeam } from "../../helpers/taskByTeam";
import {
  AtiratTop,
  ArtTop,
  ParamaTop,
  PeerasakTop,
  SatthaTop,
  MongkolTop,
} from "../../constants/teamTop";
function LocalDevProject() {
  const { loading, devData } = useAdminState().productionList;
  const [type, setType] = useState("project");
  const [data, setData] = useState([]);
  const [byTeam, setByTeam] = useState([]);
  const [atirat, setAtirat] = useState([]);
  const [atiratTask, setAtiratTask] = useState([]);
  const [art, setArt] = useState([]);
  const [artTask, setArtTask] = useState([]);
  const [mongkol, setMongkol] = useState([]);
  const [mongkolTask, setMonkolTask] = useState([]);
  const [parama, setParama] = useState([]);
  const [paramaTask, setParamaTask] = useState([]);
  const [peerasak, setPeerasak] = useState([]);
  const [peerasakTask, setPeerasakTask] = useState([]);
  const [sattha, setSattha] = useState([]);
  const [satthaTask, setSatthaTask] = useState([]);

  useEffect(() => {
    getProductionList("2024", "all");
  }, []);

  useEffect(() => {
    setData(
      devData?.filter(
        (i) =>
          i.stage === "Implementing" &&
          moment(i.finishDate, "DD/MM/YYYY").isAfter(new Date())
      )
    );

    setSattha(dataByTeam(devData, "Sattha"));
    setPeerasak(dataByTeam(devData, "Peerasak"));
    setParama(dataByTeam(devData, "Parama"));
    setMongkol(dataByTeam(devData, "Mongkol"));
    setAtirat(dataByTeam(devData, "Atirat"));
    setArt(dataByTeam(devData, "Art"));
  }, [devData]);

  useEffect(() => {
    if (atirat) {
      setAtiratTask([...AtiratTop, ...atirat]);
    }

    if (art) {
      setArtTask([...ArtTop, ...art]);
    }
    if (peerasak) {
      setPeerasakTask([...PeerasakTop, ...peerasak]);
    }

    if (mongkol) {
      setMonkolTask([...MongkolTop, ...mongkol]);
    }

    if (parama) {
      setParamaTask([...ParamaTop, ...parama]);
    }

    if (sattha) {
      setSatthaTask([...SatthaTop, ...sattha]);
    }
  }, [atirat, art, sattha, parama, peerasak, mongkol]);

  useEffect(() => {
    setByTeam([
      ...mongkolTask,
      ...atiratTask,
      ...artTask,
      ...satthaTask,
      ...peerasakTask,
      ...paramaTask,
    ]);
  }, [atiratTask, artTask, paramaTask, mongkolTask, peerasak, satthaTask]);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <div className="page-header">
          <h2>Local Dev Project List</h2>
          <div className="flex">
            <Breadcrumb page="Local Dev" link="projectList/localDev" detail />
          </div>

          <div className="toggle">
            <p>View by:</p>
            <ToggleButtonGroup
              color="primary"
              value={type}
              exclusive
              onChange={(_, newValue) => setType(newValue)}
              aria-label="Type"
            >
              <ToggleButton value="project">
                <FolderOpenIcon />
                Project
              </ToggleButton>
              <ToggleButton value="team">
                <UserGroupIcon />
                Team
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {data?.length > 0 && (
            <GanttChart
              byTeam={byTeam}
              data={data}
              link="/dashboard/localDev"
              setStatus=""
              type={type}
              atirat={atirat}
            />
          )}
        </div>
      )}
    </Container>
  );
}

export default LocalDevProject;
